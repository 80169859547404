import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { Editions } from 'src/app/core';
import { EnumService } from 'src/app/services';
import { ModalComponent } from 'src/app/shared';
import { keyValuePipeNoSort } from 'src/app/utility';

@Component({
  selector: 'wky-select-edition',
  templateUrl: './select-edition.component.html',
  styleUrls: ['./select-edition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectEditionComponent extends ModalComponent implements OnInit {
  public static readonly elementTag: string = 'wky-select-edition';
  public readonly editions: Map<Editions, string>;
  private _edition: Editions = Editions.NotSpecified;

  constructor(private _formBuilder: FormBuilder,
              enumService: EnumService,
              titleService: Title) {
    super(titleService);

    this.editions = enumService.getEditions();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this._formBuilder.group({
/* eslint-disable @typescript-eslint/unbound-method */
      edition: [this.edition, [Validators.required]]
/* eslint-enable @typescript-eslint/unbound-method */
    });
  }

  public originalOrder = (a: KeyValue<Editions, string>, b: KeyValue<Editions, string>): number => {
    return keyValuePipeNoSort(a, b);
  };

  @Input()
  public get edition(): Editions {
    return this._edition;
  }
  public set edition(edition: Editions) {
    if (edition !== this._edition) {
      this._edition = edition;

      if (this.isFormInitialized()) {
        this.formGroup.patchValue({
          edition: this._edition
        });
      }
    }
  }

  public onSubmit(): void {
    if (this.isFormValid()) {
      const formValues: any = this.formGroup.value;
      const edition: Editions = Number.parseInt(formValues.edition, 10);
      this.ok(edition);
    }
  }

  public onClickCancel(): void {
    this.cancel();
  }
}
