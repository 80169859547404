import { EnvironmentType } from 'src/app/core';
import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  config: {
    environments: [
      {
        type: EnvironmentType.Testing,
        adminApiServiceBaseUri: 'https://wky-test-asvc-admin-api.azurewebsites.net/'
      }
    ],
    firebase: {
      namespace: 'https://admin.workery.io/',
      apiKey: 'AIzaSyCc1-qGOKRRQXTjtxupNIoIk9NRPVVuJQM',
      authDomain: 'workery-admin-test.firebaseapp.com',
      projectId: 'workery-admin-test',
      storageBucket: 'workery-admin-test.appspot.com',
      locationId: 'europe-west',
      messagingSenderId: '1007113987316',
      appId: '1:1007113987316:web:2652e562d2f7d9c04faaa1',
      issuer: 'https://securetoken.google.com/workery-admin-test'
    },
    logging: {
      routing: false
    }
  }
};
