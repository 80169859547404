import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfig, appConfigToken, EnvironmentType } from '../core';
import { BaseService } from './base.service';
import { LogService } from './log.service';

// TODO: consider saving current environment somewhere between sessions

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseService implements OnDestroy {
  public readonly currentEnvironment$: Observable<EnvironmentType>;
  private readonly _currentEnvironment$: ReplaySubject<EnvironmentType> = new ReplaySubject<EnvironmentType>(1);
  private environmentTypes: EnvironmentType[] = [];
  private readonly _adminApiServiceBaseUris: Map<EnvironmentType, string> = new Map<EnvironmentType, string>();
  private _currentEnvironment: EnvironmentType = EnvironmentType.NotSpecified;

  constructor(@Inject(appConfigToken) appConfig: AppConfig,
              logService: LogService) {
    super(logService);

    for (const environment of appConfig.environments) {
      const environmentType: EnvironmentType = environment.type as EnvironmentType;
      this.environmentTypes.push(environmentType);

      this._adminApiServiceBaseUris.set(environmentType, environment.adminApiServiceBaseUri);
    }

    this.currentEnvironment$ = this._currentEnvironment$.asObservable();
    const firstEnvironment: EnvironmentType = this.environmentTypes[0];
    this.setCurrentEnvironment(firstEnvironment);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._currentEnvironment$.complete();
  }

  public setCurrentEnvironment(environment: EnvironmentType): void {
    if (environment !== this._currentEnvironment) {
      this._currentEnvironment = environment;
      this._currentEnvironment$.next(environment);
    }
  }

  public getEnvironmentTypes(): EnvironmentType[] {
    return [...this.environmentTypes];  /* Return a clone, just in case */
  }

  public getAdminApiServiceBaseUri(environment?: EnvironmentType): string {
    if (typeof(environment) === 'undefined') {
      environment = this._currentEnvironment;
    }

    const adminApiServiceBaseUri: string | undefined = this._adminApiServiceBaseUris.get(environment);
    if (typeof(adminApiServiceBaseUri) === 'undefined') {
      const message: string = `No adminApiServiceBaseUri found for '${environment}' environment`;
      this.log.error(message);
      throw new Error(message);
    }

    return adminApiServiceBaseUri;
  }
}
