import { KeyValue } from '@angular/common';

// Preserve original property order
export function keyValuePipeNoSort(a: KeyValue<any, string>, b: KeyValue<any, string>): number {
  return 0;
}

// Order by ascending property value
export function keyValuePipeSortValueAsc(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
  return a.value.localeCompare(b.value);
}
