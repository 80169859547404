import { Uuid } from './uuid';

/**
 * @returns true if the arrays contain the same string values, ignoring the array order
 */
 export function isEqualStringArrays(array1: string[], array2: string[]): boolean {
  return array1
      && array2
      && (array1.length === array2.length)
      && (array1.filter(item => !array2.includes(item)).length === 0);
}

/**
 * @param format String format using numeric placeholders - {0}, {1}, etc - for the location
 * of each argument in the output.
 */
export function stringFormat(format: string, ...args: any[] ): string {
  return format.replace(/(?<!\\){(\d+)}/g, (_match, index) => {
                  const arg: any = args[index];
                  return typeof(arg) === 'undefined' ? ''
                                                     : (arg instanceof Date ? arg.toLocaleDateString()
                                                                            : arg);
                })
               .replace('\\{', '{');
}

export function getRandomString(length: number): string {
  let value: string = '';

  while (value.length < length) {
    value = value.concat(new Uuid().toBase32());
  }

  return value.slice(0, length);
}

export async function getSha1HashValue(source: string): Promise<string> {
  let hash: string = '';

  if (source.length > 0) {
    const uint8Data: Uint8Array = new TextEncoder().encode(source);
    const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-1', uint8Data);
    const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    hash = hashArray.map(b => b.toString(16)
                               .padStart(2, '0'))
                               .join('');
  }

  return hash;
}
