import { base32guid } from '../core/model';

export const BASE32_ALPHABET: string = '0123456789abcdefghjkmnpqrstvwxyz';
const BYTE_SIZE: number = 8;
const BYTE_MASK: number = 0b1111_1111;
const BASE32_BYTE_SIZE: number = 5;
const BASE32_MASK: number = 0b0001_1111;

export class Base32 {
  /* For base32, we're using Douglas Crockford's implementation (see https://www.crockford.com/base32.html)
    but using lowercase as it's more readable.
  */

/* eslint-disable no-bitwise */
  public static encodeByteArray(bytes: Uint8Array): base32guid {
    let output: base32guid = '';
    let inputBytePosition: number = 0;
    let inputBitPosition: number = 0;     /* Offset in byte that inputBytePosition points to (L-to-R: 0 - highest bit, 7 - lowest bit) */
    let outputBytePosition: number = 0;
    let outputBitPosition: number = 0;

    while (inputBytePosition < bytes.length) {
      /* Calculate the number of bits we can extract out of current input byte to fill missing bits in the output byte */
      const bitsAvailableInByte: number = Math.min(BYTE_SIZE - inputBitPosition, BASE32_BYTE_SIZE - outputBitPosition);

      /* Make space in the output byte */
      outputBytePosition <<=  bitsAvailableInByte;

      /* Extract the part of the input byte and move it to the output byte */
      outputBytePosition |= (bytes[inputBytePosition] >> (BYTE_SIZE - (inputBitPosition + bitsAvailableInByte)));

      /* Update current sub-byte position */
      inputBitPosition += bitsAvailableInByte;

      if (inputBitPosition >= BYTE_SIZE) {
        inputBytePosition++;
        inputBitPosition = 0;
      }

      /* Update current base32 byte completion */
      outputBitPosition += bitsAvailableInByte;

      /* Check overflow or end of input array */
      if (outputBitPosition >= BASE32_BYTE_SIZE) {
        /* Drop the overflow bits */
        outputBytePosition &= BASE32_MASK;

        /* Add current Base32 byte and convert it to character */
// TODO [eslint-plugin-unicorn@>43.0.0]: check if this still needs to be overridden when https://github.com/sindresorhus/eslint-plugin-unicorn/issues/1064 is fixed
        // eslint-disable-next-line unicorn/prefer-spread
        output = output.concat(BASE32_ALPHABET[outputBytePosition]);

        /* Move to the next byte */
        outputBitPosition = 0;
      }
    }

    /* Check if we have a remainder */
    if (outputBitPosition > 0) {
      /* Move to the right bits */
      outputBytePosition <<= (BASE32_BYTE_SIZE - outputBitPosition);

      /* Drop the overflow bits */
      outputBytePosition &= BASE32_MASK;

      /* Add current Base32 byte and convert it to character */
// TODO [eslint-plugin-unicorn@>43.0.0]: check if this still needs to be overridden when https://github.com/sindresorhus/eslint-plugin-unicorn/issues/1064 is fixed
      // eslint-disable-next-line unicorn/prefer-spread
      output = output.concat(BASE32_ALPHABET[outputBytePosition]);
    }

    return output;
  }

  public static decodeToByteArray(input: string): Uint8Array {
    input = input.toLowerCase()
                 .replace(/o/g, '0')
                 .replace(/i/g, '1')
                 .replace(/l/g, '1');

    const outputLength: number = Math.floor(input.length * BASE32_BYTE_SIZE / BYTE_SIZE);
    const outputBytes: Uint8Array = new Uint8Array(outputLength);
    let base32Position: number = 0;
    let base32BitPosition: number = 0;
    let outputBytePosition: number = 0;
    let outputBitPosition: number = 0;

    while (outputBytePosition < outputLength) {
      const currentBase32Byte: number = BASE32_ALPHABET.indexOf(input[base32Position]);

      if (currentBase32Byte < 0) {
        throw new Error(`Specified string is not valid Base32 format because character '${input[base32Position]}' does not exist in Base32 alphabet`);
      }

      /* Calculate the number of bits we can extract out of current input character to fill missing bits in the output byte */
      const bitsAvailableInByte: number = Math.min(BASE32_BYTE_SIZE - base32BitPosition, BYTE_SIZE - outputBitPosition);

      /* Make space in the output byte.  Then, because Javascript numbers are floats and shifted
        bits don't get 'lost' as they would with an integer, we need to get rid of any bits higher
        than the max byte value.
      */
      const outputByte: number = outputBytes[outputBytePosition] << bitsAvailableInByte;
      outputBytes[outputBytePosition] = outputByte & BYTE_MASK;

      /* Extract the part of the input character and move it to the output byte */
      outputBytes[outputBytePosition] |= (currentBase32Byte >> (BASE32_BYTE_SIZE - (base32BitPosition + bitsAvailableInByte)));

      /* Update current sub-byte position */
      outputBitPosition += bitsAvailableInByte;

      if (outputBitPosition >= BYTE_SIZE) {
        outputBytePosition++;
        outputBitPosition = 0;
      }

      /* Update current base32 byte completion */
      base32BitPosition += bitsAvailableInByte;

      /* Check overflow or end of input array */
      if (base32BitPosition >= BASE32_BYTE_SIZE) {
        base32Position++;
        base32BitPosition = 0;
      }
    }

    return outputBytes;
  }
/* eslint-enable no-bitwise */

  public encode(input: string): string {
    const bytes: Uint8Array = this.toByteArray(input);
    return Base32.encodeByteArray(bytes);
  }

  public decode(input: string): string {
    const outputBytes: Uint8Array = Base32.decodeToByteArray(input);
    return this.fromByteArray(outputBytes);
  }

  private toByteArray(input: string): Uint8Array {
    const encoder: TextEncoder = new TextEncoder();
    const byteArray: Uint8Array = encoder.encode(input);

    return byteArray;
  }

  private fromByteArray(byteArray: Uint8Array): string {
    const decoder: TextDecoder = new TextDecoder();
    return decoder.decode(byteArray);
  }
}
