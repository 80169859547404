import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBuilding,
  faCircleCheck,
  faCircleQuestion,
  faCloudDownload,
  faShareAll,
  faUser
} from '@fortawesome/pro-regular-svg-icons';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfigToken, AuthHttpInterceptor, ResponseInterceptor } from './core';
import { AccountService,
         AdminApiService,
         AuthService,
         ConfigService,
         EnumService,
         LogService,
         ModalService,
         NotificationService
       } from './services';
import { ModalModule } from './views/modals/modal.module';
import { NotificationModule } from './views/notification/notification.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    provideFirebaseApp(() => initializeApp(environment.config.firebase)),
    provideAuth(() => getAuth()),
    AppRoutingModule,
    ModalModule
  ],
  providers: [
    AccountService,
    AdminApiService,
    AuthService,
    ConfigService,
    EnumService,
    LogService,
    ModalService,
    NotificationService,
    { provide: appConfigToken, useValue: environment.config },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
  ],
  bootstrap: [/* See below */]
})
export class AppModule implements DoBootstrap {
  constructor(private _injector: Injector,
              library: FaIconLibrary) {
    library.addIcons(
                     faBuilding,
                     faCircleCheck,
                     faCircleQuestion,
                     faCloudDownload,
                     faShareAll,
                     faUser
                    );
  }

  public ngDoBootstrap(appRef: ApplicationRef): void {
    /* In order to trigger this event handler so we can initialize the custom elements,
      the `bootstrap` property in the `@NgModule` decorator must be empty.  That therefore
      means that we have to manually bootstrap the app with `AppComponent`.
    */
    appRef.bootstrap(AppComponent);

    ModalModule.define(this._injector);
    NotificationModule.define(this._injector);
  }
}
