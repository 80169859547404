import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { NotificationComponent } from './notification.component';

export { NotificationComponent } from './notification.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    NotificationComponent
  ],
  declarations: [
    NotificationComponent
  ],
  providers: []
})
export class NotificationModule {
  public static define(injector: Injector): void {
    const elementConstructor: CustomElementConstructor = createCustomElement(NotificationComponent, { injector });
    customElements.define(NotificationComponent.elementTag, elementConstructor);
  }
}
