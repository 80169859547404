import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';

import { AuthService, ConfigService, EnumService, ModalService } from './services';
import { SelectEnvironmentComponent } from './views/modals/modal.module';

@Component({
  selector: 'wky-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnDestroy {
  public loggedIn: boolean = false;
  public navbarMenuActive: boolean = false;
  public currentUserName: string = '';
  public currentEnvironment: string = '';
  private readonly _authSubscription: Subscription;
  private readonly _configSubscription: Subscription;

  constructor(private _authService: AuthService,
              private _router: Router,
              private _modalService: ModalService,
              configService: ConfigService,
              enumService: EnumService,
              faConfig: FaConfig) {
    faConfig.defaultPrefix = 'far';

    this._authSubscription = _authService.isAuthenticated$
                                         .subscribe(loggedIn => {
                                            this.loggedIn = loggedIn;
                                            this.currentUserName = _authService.currentUserName;
                                          });

    this._configSubscription = configService.currentEnvironment$
                                            .subscribe((environment) => {
                                              this.currentEnvironment = enumService.getEnvironmentType(environment);
                                            });
  }

  public toggleNavbarMenu(): void {
    this.navbarMenuActive = !this.navbarMenuActive;
  }

  public showEnvironmentModal(): void {
    this._modalService.show(SelectEnvironmentComponent.elementTag)
                      .subscribe();
  }

  public logIn(): void {
    this._authService.login()
                     .then(loggedIn => {
                        if (loggedIn) {
// TODO: eventually redirect to user's original URL, if appropriate
                          this._router.navigate(['/home']);
                        }
                      });
    this.navbarMenuActive = false;
  }

  public logOut(): void {
    this._authService.logout()
                     .then(loggedOut => {
                        if (loggedOut) {
                          this._router.navigate(['/login']);
                        }
                      });
    this.navbarMenuActive = false;
  }

  public ngOnDestroy(): void {
    this._authSubscription.unsubscribe();
    this._configSubscription.unsubscribe();
  }
}
