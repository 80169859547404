import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, switchMap } from 'rxjs';

import { AuthService, ConfigService, LogService } from '../services';
import { Logger } from './model';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private readonly _log: Logger;
  private _adminApiServiceBaseUri: string = '';

  constructor(private _authService: AuthService,
              configService: ConfigService,
              logService: LogService) {
     this._log = logService.getLogger('AuthHttpInterceptor');

    configService.currentEnvironment$
                //  .pipe(takeUntil(this.isBeingDestroyed$))
                 .subscribe((_environment) => {
                    this._adminApiServiceBaseUri = configService.getAdminApiServiceBaseUri()
                                                                .toUpperCase();
                  });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._log.assert(this._adminApiServiceBaseUri.length > 0, 'this._adminApiServiceBaseUri hasn\'t been initialized');

    return from(this._authService.getAccessToken())
              .pipe(switchMap((accessToken) => {
                      const isApiUrl: boolean = request.url.toUpperCase()
                                                           .startsWith(this._adminApiServiceBaseUri);
                      if (isApiUrl && accessToken.length > 0) {
                        this._log.debug(`Setting 'Authorization' header on HTTP request`);
                        request = request.clone({
                                                  setHeaders: {
                                                    Authorization: `Bearer ${accessToken}`
                                                  }
                                                });
                      // } else {
                      //   const url: URL = new URL(request.urlWithParams);
                      //   const userInfo: string | undefined = this._configService.getServerUserInfo(url.origin);
                      //   if (userInfo) {
                      //     request = request.clone({
                      //                               setHeaders: {
                      //                                 Authorization: `Basic ${btoa(userInfo)}`
                      //                               }
                      //                             });
                      //   }
                      }

                      return next.handle(request)
                                 .pipe(catchError((error: any) => {
                                        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
                                          /* Either the user isn't logged in or the access token credentials are no longer valid */
                                          this._log.debug('Request is unauthorized');
                                        }

                                        throw error;
                                      }));
                    }));
  }
}
