import { Injectable } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { fromEvent } from 'rxjs';

import { Colour } from '../core';
import { NotificationComponent } from '../views/notification/notification.module';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public show(message: string, colour: Colour): void {
    const notification: NgElement & WithProperties<NotificationComponent> = document.createElement(NotificationComponent.elementTag) as any;

    notification.message = message;
    notification.colour = colour;

    fromEvent(notification, 'closed')
        .subscribe(() => {
                    notification.remove();
                  });
    document.body.querySelector('main')?.append(notification);
  }
}
