<div class="modal-background"></div>
<form [formGroup]="this.formGroup" aria-labelledby="modalTitle" role="document" (ngSubmit)="onSubmit()" class="modal-card">
	<header class="modal-card-head">
		<p id="modalTitle" class="modal-card-title">{{ this.title }}</p>
		<button type="button" (click)="onClickCancel()" class="delete" aria-label="close"></button>
	</header>
	<section class="modal-card-body">
		<div class="field">
			<label for="date" class="label">Date</label>
			<div class="control">
				<input type="date" id="date" formControlName="date" class="input">
			</div>
			<p class="help">Time will be set to 00:00</p>
		</div>
	</section>
	<footer class="modal-card-foot">
		<button type="submit" class="button is-success">Save changes</button>
		<button type="button" (click)="onClickCancel()" class="button">Cancel</button>
	</footer>
</form>
