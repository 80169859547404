import { Directive } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, takeUntil } from 'rxjs';

import { EnvironmentType } from '../core';
import { ConfigService } from '../services';
import { PageComponent } from './page.component';

/** Implements: OnInit (inherited), OnDestroy (inherited) */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- required for abstract base classes that would otherwise have to be decorated with @Component to avoid NG2007 errors
export abstract class EnvironmentPageComponent extends PageComponent {
  protected environmentChange$: Observable<EnvironmentType>;

  constructor(configService: ConfigService,
              titleService: Title) {
    super(titleService);
    this.environmentChange$ = configService.currentEnvironment$
                                           .pipe(takeUntil(this.isBeingDestroyed$));
  }
}
