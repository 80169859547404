export function getDate(dateValue: Date | string): Date {
  return typeof(dateValue) === 'string' ? new Date(dateValue)
                                        : dateValue;
}

export function getSafeDate(dateValue: Date | string | undefined): Date | undefined {
  return typeof(dateValue) === 'string' ? new Date(dateValue)
                                        : dateValue;
}

/**
 * @returns a string containing the date value (no time) formatted to suit an `input type="date"`
 * HTML element.
 */
export function getDateInputString(date: Date | undefined): string {
  return date ? date.toISOString().split('T')[0]
              : '';
}
