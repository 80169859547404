import { InjectionToken } from '@angular/core';

import { AuthConfig } from './auth-config';
import { EnvironmentConfig } from './environment-config';
import { LoggingConfig } from './logging-config';

export const appConfigToken: InjectionToken<AppConfig> = new InjectionToken<AppConfig>('AppConfig');

export interface AppConfig {
  environments: EnvironmentConfig[];
  firebase: AuthConfig;
  logging: LoggingConfig;
}
