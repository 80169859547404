import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Colour } from 'src/app/core';

const HALF_SECOND_MS: number = 500;

@Component({
  selector: 'wky-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, AfterViewInit, OnDestroy {
  public static readonly elementTag: string = 'wky-notification';
  @Input() public message: string = '';
  @Output() public closed = new EventEmitter();
  @HostBinding('attr.class') public cssClass: string = '';
  private _colour: Colour = Colour.Primary;
  private _isVisible: boolean = false;

  public ngOnInit(): void {
    this.updateCssClass();
  }

  public ngAfterViewInit(): void {
    this._isVisible = true;
    setTimeout(() => this.updateCssClass());
  }

  public ngOnDestroy(): void {
    this._isVisible = false;
    this.updateCssClass();
  }

  @Input()
  public get colour(): Colour {
    return this._colour;
  }
  public set colour(colour: Colour) {
    this._colour = colour;
    this.updateCssClass();
  }

  public onClickClose(): void {
    this._isVisible = false;
    this.updateCssClass();

    setTimeout(() => {
      this.closed.emit();
      this.closed.complete();
    }, HALF_SECOND_MS);
  }

  private updateCssClass(): void {
    this.cssClass = 'notification ' + this._colour + (this._isVisible ? ' show' : '');
  }
}
