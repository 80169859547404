import { Editions } from '../enums';

export interface AccountProfileUser {
  userId: string;
  idToken: string;
  authUserId: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  dateCreated: Date;
  disabled: boolean;
  lastActivityTimestamp: Date | undefined;
  accountEdition: Editions;
  accountExpiryDate: Date;
}
