import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectDateComponent } from './select-date.component';
import { SelectEditionComponent } from './select-edition.component';
import { SelectEnvironmentComponent } from './select-environment.component';

export { SelectDateComponent } from './select-date.component';
export { SelectEditionComponent } from './select-edition.component';
export { SelectEnvironmentComponent } from './select-environment.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SelectDateComponent,
    SelectEditionComponent,
    SelectEnvironmentComponent
  ],
  declarations: [
    SelectDateComponent,
    SelectEditionComponent,
    SelectEnvironmentComponent
  ],
  providers: []
})
export class ModalModule {
  public static define(injector: Injector): void {
    let elementConstructor: CustomElementConstructor = createCustomElement(SelectDateComponent, { injector });
    customElements.define(SelectDateComponent.elementTag, elementConstructor);

    elementConstructor = createCustomElement(SelectEditionComponent, { injector });
    customElements.define(SelectEditionComponent.elementTag, elementConstructor);

    elementConstructor = createCustomElement(SelectEnvironmentComponent, { injector });
    customElements.define(SelectEnvironmentComponent.elementTag, elementConstructor);
  }
}
