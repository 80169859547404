export const enum AriaRole {
  AlertDialog = 'alertdialog',
  Dialog = 'dialog'
}

/* Okay, this is not really an enum; more a convenient group of string constants */
export const enum Colour {
  Primary = 'is-primary',
  Link = 'is-link',
  Info = 'is-info',
  Success = 'is-success',
  Warning = 'is-warning',
  Danger = 'is-danger'
}

export /* not const */ enum Editions {
   NotSpecified = 0x0,
   Trial        = 0x1,
   Standard     = 0x2
}

export const enum EnvironmentType {
  NotSpecified = '',
  Development = 'develop',
  Testing = 'test',
  Production = 'prod'
}


