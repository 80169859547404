import { Editions } from '../enums';

export interface AccountSummary {
  accountId: string;
  idToken: string;
  companyName: string;
  edition: Editions;
  dateCreated: Date;
  dateCancelled: Date | undefined;
  expiryDate: Date;
}
