import { AuthGuard, AuthPipe, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { Data } from '@angular/router';

// eslint-disable-next-line @typescript-eslint/typedef
const redirectUnauthorizedToLogin = (): AuthPipe => redirectUnauthorizedTo(['login']);

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type
export const canActivateIfAuthorized = (data: Data = {}) => ({
  canActivate: [ AuthGuard ],
  data: {
    authGuardPipe: redirectUnauthorizedToLogin,
    ...data
  }
});
