import { Injectable } from '@angular/core';

import { Editions, EnvironmentType } from '../core';

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  private _editions!: Map<number, string>;
  // private _emailTemplateTypes!: Map<number, string>;
  private _environmentTypes!: Map<string, string>;
  // private _regions!: Map<Region, string>;
  // private _serverTypes!: Map<ServerType, string>;

  public getEditions(includeBlank: boolean = false): Map<Editions, string> {
    this.initializeEditions();

    const editions: Map<number, string> = new Map(this._editions);
    if (!includeBlank) {
      editions.delete(Editions.NotSpecified);
    }

    return editions;
  }

  public getEdition(edition: Editions): string {
    this.initializeEditions();
    return this._editions.get(edition) || '';
  }

  private initializeEditions(): void {
    if (!this._editions) {
      this._editions = new Map([
        [Editions.NotSpecified, 'Not Specified'],
        [Editions.Trial, 'Trial'],
        [Editions.Standard, 'Standard']
      ]);
    }
  }

  // public getEmailTemplateTypes(): Map<number, string> {
  //   this.initializeEmailTemplateTypes();
  //   return new Map(this._emailTemplateTypes);
  // }

  // public getEmailTemplateType(type: EmailTemplateType): string {
  //   this.initializeEmailTemplateTypes();
  //   return this._emailTemplateTypes.get(type) || '';
  // }

  // private initializeEmailTemplateTypes(): void {
  //   if (!this._emailTemplateTypes) {
  //     this._emailTemplateTypes = new Map([
  //       [Number(EmailTemplateType.Welcome), 'New Account Welcome'],
  //       [Number(EmailTemplateType.EmailVerification), 'Email Verification'],
  //       [Number(EmailTemplateType.Invoice), 'Invoice'],
  //       [Number(EmailTemplateType.Quote), 'Quote']
  //     ]);
  //   }
  // }

  public getEnvironmentType(environment: EnvironmentType): string {
    this.initializeEnvironmentTypes();
    return this._environmentTypes.get(environment) || '';
  }

  private initializeEnvironmentTypes(): void {
    if (!this._environmentTypes) {
      this._environmentTypes = new Map([
        [EnvironmentType.Development, 'Development'],
        [EnvironmentType.Testing, 'Testing'],
        [EnvironmentType.Production, 'Production']
      ]);
    }
  }

  // public getRegions(): Map<Region, string> {
  //   this.initializeRegions();
  //   return new Map(this._regions);
  // }

  // public getRegion(region: Region): string {
  //   this.initializeRegions();
  //   return this._regions.get(region) || '';
  // }

  // public getRegionForRoute(region: string): string {
  //   return region === Region.Global ? 'global' : region;
  // }

  // public getRegionFromRoute(region: string): Region {
  //   return (region === 'global' ? Region.Global : region) as Region;
  // }

  // private initializeRegions(): void {
  //   if (!this._regions) {
  //     this._regions = new Map([
  //       [Region.Global, '(Global)'],
  //       [Region.UnitedKingdom, 'United Kingdom'],
  //       [Region.Europe, 'Europe'],
  //       [Region.UnitedStates, 'United States']
  //     ]);
  //   }
  // }

  // public getServerType(type: ServerType): string {
  //   this.initializeServerTypes();
  //   return this._serverTypes.get(type) || 'N/A';
  // }

  // private initializeServerTypes(): void {
  //   if (!this._serverTypes) {
  //     this._serverTypes = new Map([
  //       [ServerType.Primary, 'Primary'],
  //       [ServerType.Secondary, 'Secondary']
  //     ]);
  //   }
  // }
}
