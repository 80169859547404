import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { canActivateIfAuthorized } from './core';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'home', loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule), ...canActivateIfAuthorized()},
  { path: 'login', loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule) },
  { path: 'accounts/:id', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule), ...canActivateIfAuthorized()},
  { path: 'accounts', loadChildren: () => import('./views/accounts/accounts.module').then(m => m.AccountsModule), ...canActivateIfAuthorized()}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
                         {
                          enableTracing: environment.config.logging.routing
                         })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
