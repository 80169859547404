<div class="modal-background"></div>
<form [formGroup]="this.formGroup" aria-labelledby="modalTitle" role="document" (ngSubmit)="onSubmit()" class="modal-card">
	<header class="modal-card-head">
		<p id="modalTitle" class="modal-card-title">Change Edition</p>
		<button type="button" (click)="onClickCancel()" class="delete" aria-label="close"></button>
	</header>
	<section class="modal-card-body">
		<div class="field">
			<label for="edition" class="label">Edition</label>
			<div class="control">
				<div class="select">
					<select id="edition" formControlName="edition" required>
						<option *ngFor="let edition of this.editions | keyvalue: this.originalOrder" [value]="edition.key">
							{{ edition.value }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</section>
	<footer class="modal-card-foot">
		<button type="submit" class="button is-success">Save changes</button>
		<button type="button" (click)="onClickCancel()" class="button">Cancel</button>
	</footer>
</form>
