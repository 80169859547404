import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AccountProfile, AccountSummaryList, AccountUpdate } from '../core/model';
import { getDate, getSafeDate } from '../utility';
import { AdminApiService } from './admin-api.service';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  constructor(private _adminApiService: AdminApiService,
              logService: LogService) {
    super(logService);
  }

  public getAccountSummaries(page: number, pageSize: number): Observable<AccountSummaryList> {
    return this._adminApiService.get<AccountSummaryList>(`accounts?page=${encodeURIComponent(page)}&pagesize=${encodeURIComponent(pageSize)}`)
                                .pipe(map((list) => {
                                  list.accounts.forEach(summary => {
                                                  summary.dateCreated = getDate(summary.dateCreated);
                                                  summary.dateCancelled = getSafeDate(summary.dateCancelled);
                                                  summary.expiryDate = getDate(summary.expiryDate);
                                                });
                                  return list;
                                }));
  }

  public getAccountProfile(accountId: string): Observable<AccountProfile> {
    accountId = encodeURIComponent(accountId);
    return this._adminApiService.get<AccountProfile>(`accounts/${accountId}/profile`)
                                .pipe(map((profile) => {
                                  profile.dateCreated = getDate(profile.dateCreated);
                                  profile.dateCancelled = getSafeDate(profile.dateCancelled);
                                  profile.expiryDate = getDate(profile.expiryDate);

                                  profile.users.forEach(user => {
                                                  user.dateCreated = getDate(user.dateCreated);
                                                  user.lastActivityTimestamp = getSafeDate(user.lastActivityTimestamp);
                                                  user.accountExpiryDate = getDate(user.accountExpiryDate);
                                                });
                                  return profile;
                                }));
  }

  public update(accountId: string, update: AccountUpdate): Observable<void> {
    accountId = encodeURIComponent(accountId);
    return this._adminApiService.patch(`accounts/${accountId}`, update);
  }
}
