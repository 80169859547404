import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs';

import { EnvironmentType } from 'src/app/core';
import { ConfigService, EnumService } from 'src/app/services';
import { ModalComponent } from 'src/app/shared';
import { keyValuePipeNoSort } from 'src/app/utility';

@Component({
  selector: 'wky-select-environment',
  templateUrl: './select-environment.component.html',
  styleUrls: ['./select-environment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectEnvironmentComponent extends ModalComponent implements OnInit {
  public static readonly elementTag: string = 'wky-select-environment';
  public readonly environments: Map<EnvironmentType, string> = new Map<EnvironmentType, string>();
  public currentEnvironment: EnvironmentType = EnvironmentType.NotSpecified;

  constructor(private _configService: ConfigService,
              private _formBuilder: FormBuilder,
              private _enumService: EnumService,
              titleService: Title) {
    super(titleService);

    _configService.currentEnvironment$
                  .pipe(takeUntil(this.isBeingDestroyed$))
                  .subscribe((environment: EnvironmentType) => {
                    this.setCurrentEnvironment(environment);
                  });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const environments: EnvironmentType[] = this._configService.getEnvironmentTypes();
    for (const environment of environments) {
      this.environments.set(environment, this._enumService.getEnvironmentType(environment));
    }

    this.formGroup = this._formBuilder.group({
/* eslint-disable @typescript-eslint/unbound-method */
      environment: [this.currentEnvironment, [Validators.required]]
/* eslint-enable @typescript-eslint/unbound-method */
    });
  }

  public originalOrder = (a: KeyValue<EnvironmentType, string>, b: KeyValue<EnvironmentType, string>): number => {
    return keyValuePipeNoSort(a, b);
  };

  private setCurrentEnvironment(environment: EnvironmentType): void {
    if (environment !== this.currentEnvironment) {
      this.currentEnvironment = environment;

      if (this.isFormInitialized()) {
        this.formGroup.patchValue({
          environment: this.currentEnvironment
        });
      }
    }
  }

  public onSubmit(): void {
    if (this.isFormValid()) {
      const formValues: any = this.formGroup.value;

      this._configService.setCurrentEnvironment(formValues.environment);
      this.ok(formValues.environment);
    }
  }

  public onClickCancel(): void {
    this.cancel();
  }
}
