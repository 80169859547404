<header class="site-header">
	<nav class="navbar is-primary" role="navigation" aria-label="main navigation">
		<div class="navbar-brand">
			<div class="navbar-item">
				<h1>Workery Admin Console</h1>
			</div>
			<a role="button" (click)="toggleNavbarMenu()" [ngClass]="{'navbar-burger': true, 'is-active': this.navbarMenuActive}" aria-label="menu" aria-expanded="false" data-target="navbarMenu">
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		<div id="navbarMenu" [ngClass]="{'navbar-menu': true, 'is-active': this.navbarMenuActive}">
			<div class="navbar-start"></div>
			<div class="navbar-end">
				<div *ngIf="this.loggedIn" class="navbar-item">
					{{ this.currentUserName }}
				</div>
				<div class="navbar-item">
					<div class="buttons">
						<button *ngIf="!this.loggedIn" (click)="logIn()" class="button is-light">Log In</button>
						<button *ngIf="this.loggedIn" (click)="logOut()" class="button is-light">Log Out</button>
					</div>
				</div>
			</div>
		</div>
	</nav>
</header>
<nav *ngIf="this.loggedIn" class="main-menu menu">
	<button (click)="showEnvironmentModal()" title="Change current environment" class="button is-outlined is-fullwidth">{{ this.currentEnvironment }}</button>
	<p class="menu-label">
	  Configuration
	</p>
	<ul class="menu-list">
		<li><a routerLink="/accounts" routerLinkActive="is-active">Accounts</a></li>
	</ul>
</nav>
<main class="main-content">
	<router-outlet></router-outlet>
</main>
