import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { ModalComponent } from 'src/app/shared';
import { getDateInputString } from 'src/app/utility';

@Component({
  selector: 'wky-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDateComponent extends ModalComponent implements OnInit {
  public static readonly elementTag: string = 'wky-select-date';
  @Input() public title: string = 'Select Date';
  private _date: Date | undefined;

  constructor(private _formBuilder: FormBuilder,
              titleService: Title) {
    super(titleService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this._formBuilder.group({
      date: getDateInputString(this._date)
    });
  }

  @Input()
  public get date(): Date | undefined {
    return this._date;
  }
  public set date(date: Date | undefined) {
    if (date !== this._date) {
      this._date = date;

      if (this.isFormInitialized()) {
        this.formGroup.patchValue({
          date: getDateInputString(this._date)
        });
      }
    }
  }

  public onSubmit(): void {
    if (this.isFormValid()) {
      const formValues: any = this.formGroup.value;
      this.ok(formValues.date);
    }
  }

  public onClickCancel(): void {
    this.cancel();
  }
}
