import { Injectable, OnDestroy } from '@angular/core';
import { Auth, browserSessionPersistence, GoogleAuthProvider, signInWithPopup, Unsubscribe, User } from '@angular/fire/auth';
import { Observable, ReplaySubject } from 'rxjs';

import { Logger } from '../core/model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public readonly isAuthenticated$: Observable<boolean>;
  private readonly _isAuthenticated$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private readonly _authStateUnsubscribe: Unsubscribe;
  private _currentUserName: string = '';
  private _currentEmail: string = '';
  private _accessToken: string = '';
  private readonly _log: Logger;

  constructor(private _fireAuthService: Auth,
              logService: LogService) {
    this._log = logService.getLogger('AuthService');
    this._authStateUnsubscribe = _fireAuthService.onAuthStateChanged(this.onAuthStateChanged,
                                                                     (error) => {
                                                                      this._log.fatal('Error from Auth.onAuthStateChanged', error);
                                                                     });

    this.isAuthenticated$ = this._isAuthenticated$.asObservable();
    _fireAuthService.setPersistence(browserSessionPersistence);
    _fireAuthService.app.automaticDataCollectionEnabled = false;

// TODO: maybe make this an optional setting in case users want to remain logged in at Google?
    const provider: GoogleAuthProvider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
  }

  public ngOnDestroy(): void {
    this._isAuthenticated$.complete();
    this._authStateUnsubscribe();
  }

  public get currentUserName(): string {
    return this._currentUserName;
  }

  public get currentEmail(): string {
    return this._currentEmail;
  }

  public async getAccessToken(forceRefresh?: boolean): Promise<string> {
    let accessToken: string = this._accessToken;

    if (accessToken.length > 0) {
      accessToken = await this._fireAuthService.currentUser?.getIdToken(forceRefresh) ?? '';
    }

    return accessToken;
  }

  public login(): Promise<boolean> {
    return signInWithPopup(this._fireAuthService, new GoogleAuthProvider())
              .then(userCredential => {
                const isAuthenticated: boolean = (userCredential.user !== null);

                /* Set 'static' properties before updating the observable */
                this._currentUserName = userCredential.user?.displayName ?? '';
                this._currentEmail = userCredential.user?.email ?? '';
                this.setAuthenticationState(isAuthenticated);

                return isAuthenticated;
              }, (error: any) => {
                this._log.error('Authentication failed', error);
                this.setAuthenticationState(false);

                return false;
              });
  }

  public logout(): Promise<boolean> {
    return this._fireAuthService.signOut()
                                .then(() => {
                                  this.setAuthenticationState(false);
                                  return true;
                                }, (error: any) => {
                                  this._log.error('Error signing out of application', error);
                                  return false;
                                });
  }

  private onAuthStateChanged = (user: User | null): void => {
    if (user) {
      user.getIdTokenResult()
          .then((idTokenResult) => {
            this._log.debug('AngularFireAuth.idTokenResult received');

            this._accessToken = idTokenResult.token;
            this.setAuthenticationState(true);
          })
          .catch((error) => {
            this._log.error('Error getting id token result - logging user out', error);
            this.logout();
          });
    } else {
      this._log.debug('AngularFireAuth.idTokenResult not available');

      /* Update the auth state BEFORE shutting down to ensure that components
        (hopefully) remove any references before they disappear.
      */
      this.setAuthenticationState(false);
    }
  };

  private setAuthenticationState(isAuthenticated: boolean): void {
    this._log.debug(`Auth State => ${isAuthenticated ? 'authenticated' : 'NOT authenticated'}`);

    if (!isAuthenticated) {
      /* Clear 'static' properties before updating the observable */
      this._currentUserName = '';
      this._currentEmail = '';
    }

    this._isAuthenticated$.next(isAuthenticated);
  }
}
