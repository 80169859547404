import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, takeUntil } from 'rxjs';

import { BaseService } from './base.service';
import { ConfigService } from './config.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService extends BaseService {
  private _adminApiServiceBaseUri: string = '';

  constructor(private _http: HttpClient,
              configService: ConfigService,
              logService: LogService) {
    super(logService);

    configService.currentEnvironment$
                 .pipe(takeUntil(this.isBeingDestroyed$))
                 .subscribe((_environment) => {
                    this._adminApiServiceBaseUri = configService.getAdminApiServiceBaseUri();
                  });
  }

  public get<T>(url: string, onErrorValue?: T): Observable<T> {
    url = this._adminApiServiceBaseUri + url;
    return this._http.get<T>(url)
                     .pipe(catchError(err => {
                            this.log.error('API service error calling ' + url, err);
                            if (!onErrorValue) {
                              throw err;
                            }
                            return of<T>(onErrorValue);
                          }));
  }

  public put<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.put<T>(this._adminApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }

  public post<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.post<T>(this._adminApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }

  public patch<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.patch<T>(this._adminApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }
}
